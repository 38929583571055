.product, .next-page-product {
    .product-container {
        position: relative;
        background-color: #FFF;
        padding: 5px;
        border: solid 2px #EFEFEF;
    }
    .ShowDiscount {
        position: absolute;
        z-index: 5;
        top: 5px;
        right: 5px;
        border-radius: 100%;
        padding: 7px 5px;
        background-color: rgba(255, 255, 255, 0.8);
        border: solid 1px;
        /*border-radius: 100%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 7px;
        padding-bottom: 7px;
        right: 0px;
        top: 3px;
        border: solid 1px;*/
        span {
            text-align: center;
            display: block;
            font-size: 13px 13px;
        }
    }
    .signs {
        position: absolute;
        top: 5px;
        left: 5px;        
        z-index: 1;
        li {
            margin-bottom: 3px;
            span {
                display: inline-block;
                font-size: 14px 1em;
                padding: 3px;
                border-radius: 3px;
            }
        }
    }
    .thumbnail {
        position: relative;
        overflow: hidden;
        a {
            display: block;
        }
        .thumb-inner {
            max-height: 180px;
            padding: 5px;
        }
        img {
            @extend responsive-image;
            margin: 0 auto;
        }
        border-bottom: solid 1px $grey-light;
    }
    .inner {
        padding-top: 5px;
    }
    .details,
    .description {
        font-size: 13px;
    }
    .details {        
        padding: 5px 0;
        line-height: 1.2em;

        li {
            margin-bottom: 2px;
        }

        span {
            font-weight: $font-semibold;
        }
    }
    .description {
        line-height: 1.2em;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    h3 {
        overflow: hidden;
        padding: 5px;
        font-size: 15px 1.2em;
        font-weight: $font-semibold;

        a {            
            text-decoration: none;
        }
    }
    .price {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px 1.2em;
        .view-price {
            b {
                font-size: 16px 1.2em;
            }
        }
        .old-price {
            text-decoration: line-through;
        }
        small {
            display: block;
           font-size: 12px 1.2em;
        }
    }
    .buy {
        .btn {
            width: 100%;
        }
    }
    .book-compare {
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        position: absolute;
        bottom: -37px;
        left: 0px;
        li {
            display: inline-block;
            a {
                background-color: #FFF;
                border: solid 1px #efefef;
                border-radius: 3px;
                width: 30px;
                height: 30px;
                @extend icomoon;
                text-decoration: none;
                display: table;
                &:hover {
                    box-shadow: 0px 0px 10px rgba(50, 50, 50, 0.16);
                }
                &:before {
                    display: table-cell;
                    height: 100%;
                    text-align: center;
                    vertical-align: middle;
                    width: 100%;
                }
                &.AddBookmarkedProduct {
                    &:before {
                        content: "\e608";
                    }
                }
                &.AddCompareItem {
                    &:before {
                        content: "\e606";
                    }
                }
            }
        }
    }
    &:hover {
        .book-compare {
            bottom: 3px;
        }
    }
    &.next-page-product{
        @media (max-width: 1100px) {
            display: none !important;
        }
        .product-container{
            background-color: $grey-light;
            &:before{
                @extend icomoon;
                content: "\e60f";
                font-size: 130px;
                color: $black;
            }
        }
    }
}
