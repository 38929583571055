$cart-monitor: 850px;
$cart-phablet: $phablet;

table {
    border-spacing: 0;
}

.cart-form {
    + h2 {
        margin-top: 20px;
        + p {
            margin-bottom: 1em;
        }
    }
}

.cart-list {
    width: 100%;
    .table-header {
        display: none;
        @media(width > $cart-monitor) {
            display: table-row;
        }
    }
    tr {
        &.table-header {
            td {
                background-color: #f6f6f6;
                color: black;
            }
            ul {
                li {
                    height: 50px;
                }
            }
        }
        &.product-row {
            font-size: 16px;
            small {
                font-size: 12px;
            }
            td {
                background-color: #FFF;
                &.product-remove {
                    border-bottom: 1px solid $border-color;
                }
                @media(width >$cart-monitor) {
                    border-bottom: 1px solid $border-color;
                }
            }
        }

        &.product-gift {
            .photo {
                @mixin icon before, "\e99f";

                &:before {
                    position: absolute;
                    display: block;                    
                    right: 10px;
                    bottom: 10px;
                    font-size: 18px;
                    font-weight: bold;
                    padding-top: 5px;
                    width: 29px;
                    height: 29px;
                    text-align: center;
                    color: $red-light;
                    background: rgba(255,255,255,.9);
                    border-radius: 50%;
                    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
                }
            }
        }

        td {
            vertical-align: middle;
        }
    }
    .td-label {
        display: block;
        font-size: 14px 1.2em;
        @media(width > $cart-monitor) {
            display: none;
        }
    }
    ul.table-cells {
        @extend .clearfix;
        width: 100%;
        display: table;
        li {
            display: table-cell;
            vertical-align: middle;
            padding: 5px;

            /*@media(width >$cart-monitor) {
                padding: 5px;
            }*/
        }
        .Components {
            display: flex;
            font-size: 13px; 

            li {
                padding: 0;
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;

                    &::after {
                        display: none;
                    }
                }

                &::after {
                    content: ",";
                    margin-left: -3px;
                }
            }
        }
    }
    td {
        &.product-view {
            width: 100%;
            float: left;
            @media(width >$cart-monitor) {
                width: 45%;
                float: none;
            }
            .photo {
                position: relative;
                width: 120px;
                /*width: 25%;*/
                text-align: center;

                img {
                    max-width: 90px;
                    max-height: 90px;
                    padding: 8px;
                }
            }
            .name {
                width: calc(100% - 120px);

                > p {
                    font-size: 14px;
                }
            }
        }
        &.product-price {
            width: 100%;
            float: left;
            @media(width >$cart-monitor) {
                width: 50%;
                float: none;
            }
            /*ul.table-cells {
                li {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    @media(width >$cart-monitor) {
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
                }
            }*/
            .view-price {
                width: 100%;
                float: left;
                @media(width >$cart-phablet) {
                    width: 35%;
                    float: none;
                }
                small {
                    display: block;
                    font-size: 13px 1.2em;
                    color: $grey;
                }
            }
            .count {
                width: 100%;
                float: left;
                @media(width >$cart-monitor) {
                    width: 30%;
                    float: none;
                }
                input {
                    width: 50px;
                    text-align: center;
                    display: inline-block;
                }
            }
        }
        &.product-remove {
            text-align: center;
            .remove {
                width: 100%;
            }
            a {
                margin-top: 8px;
                margin-bottom: 8px;
                display: block;
                text-decoration: none;
                .cart-remove {
                    @mixin icon before, "\e603";
                    &:before {
                        color: #9B1414;
                        @media(width >max-width $cart-monitor) {
                            color: white;
                        }
                    }
                }
                @media(width >max-width $cart-monitor) {
                    background-color: #9B1414;
                    color: white;
                    text-decoration: none;
                }
            }
            width: 100%;
            float: left;
            @media(width >$cart-monitor) {
                width: 5%;
                float: none;
                a {
                    display: inline-block;
                }
            }
        }
    }
    .table-footer {
        &.total-price {
            white-space: nowrap;
            background-color: $light-gray;
            td {
                text-align: center;
                @media(width >$cart-monitor) {
                    text-align: left;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                &.product-view {
                    @media(width >max-width $cart-monitor) {
                        display: none;
                    }
                    ;
                }
                &.product-price {
                    font-size: 30px 1.2em;
                    width: 100%;
                    @media(width >$cart-monitor) {
                        width: 55%;
                    }
                    .view-price {
                        @media(width >max-width $cart-monitor) {
                            width: 100%;
                            float: left;
                        }
                    }
                    .unit-price {
                        width: 100%;
                        margin-bottom: -10px;

                        @media(width >$cart-monitor) {
                            width: 65%;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            h3 { margin-bottom: 0; }
        }
    }
    .nav-buttons {
        margin: 10px 0;
        @extend .clearfix;

        .button {            
            .btn {
                @media(max-width: 480px) {
                    width: 100%;
                }
            }

            &.CartContinue {
                @media(min-width: 480px) {
                    float: left;
                }
            }

            &.CartDeleteAll {
                margin-top: 10px;

                @media(min-width: 480px) {
                    float: right;
                    margin-top: 0;
                }
            }

            &.CartRecalculate {
                display: none;
            } 
        }
    }
    .checkout-button {
        text-align: right;
        margin-top: 10px;

        @media(width >$cart-monitor) {
            margin-top: 20px;
        }
        
        .btn {
            text-transform: uppercase;
            font-weight: bold;

            @media(max-width: 480px) {
                width: 100%;
            }
        }
    }
}

#OrderGift {
    margin-top: 2em;

    > p {
        margin-bottom: 1em;
    }
}

.orderGift {
    @extend .clearfix;

    button {
        float: right;
    }
}

.orderGiftRange {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 5px 10px;
    background: $grey-light;

    @media(min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;    
    }

    > span {
        color: $grey;
        font-weight: 400;
    }
}

.orderGiftList {    
    display: flex;    
    flex-flow: row wrap;
    margin: 10px 0;    

    li,
    label {        
        text-align: center;
    }

    li {
        width: 100%;
        padding: 10px;
        border: 1px solid transparent;

        @media(min-width: 420px) {
            width: 50%;
        }

        @media(min-width: 600px) {
            width: 33%;
        }

        @media(min-width: 800px) {
            width: 25%;
        }

        @media(min-width: 1025px) {
            width: 20%;
        }

        &.active {
            border-color: #b2b2b2;

            .disabled& {
                border: 1px solid transparent;
            }
        }
    }

    label {
        display: block;
        padding-top: 30px;
        margin-top: -30px;
        margin-bottom: 0;

        .disabled& {
            cursor: not-allowed;
        }
    }

    img {
        display: block;        
        width: auto;
        height: 90px;
        padding: 8px;
        margin: 0 auto;

        .disabled& {
            filter: grayscale(100%);
            opacity: .5;
        }
    }

    .name {
        display: inline-block;
        max-width: 230px;

        @media(min-width: 420px) {
            max-width: none;
        }
    }

    input[type="radio"] {
        margin-bottom: .5em;
    }
}


#InshopBS{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  #BonusCode{
    width: 150px;
    margin-right: 8px;
    margin-left: 8px;
  }
}