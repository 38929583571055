$sidebar-width-tablet: 40%;
$sidebar-width-phablet: 70%;
$sidebar-width-mobil: 90%;
$darkBg: #232323;
$transition-duration: 0.5s;

.device-sidebar {
    display: none;
    @media(width <= 1025px){
        .body-open &{
        display: block;
        }
    }
    @media(width > 1025px){
        display: block;
    }

    .user-navigation {
        display: none;
        
        @media(width <= 600px) {
            display: block;
        }

        li {
            position: relative;            

            > a {
                display: block;
                color: $black;
                text-decoration: none;
                padding: 9px 10px;
                background: $grey-light;
                border-bottom: 1px solid #ccc;

                &:hover {
                    background: $grey-dark;
                }               
            }

            &.dropdown-menu-parent {
                > a {
                    padding-right: 40px;
                }

                .ico-drop-down::after {
                    content: "\e617";                    
                    right: 14px;
                    top: 20px;
                    font-size: 17px;
                    color: $black;
                    transition: .1s linear all;
                }

                &.open {
                    border-bottom: none;

                   .ico-drop-down::after {
                        content: "\e614";
                    } 
                }
            }
        }

        > li:last-child {            
            > a {
                border-bottom-width: 5px;
            }            
        }

        .dropdown-menu {
            position: static;
            box-shadow: none;

            li {
                > a {
                    background: white;
                    
                    /*&:hover {
                        color: $red-light;
                    }*/
                }
            }
        }
    }
}

.device-header, .device-search {
    display: none;
}

.body-open, .body-open-params {
    .device-header {
        display: block;
        height: 40px;
        border-bottom: solid 1px $darkBg;
        a {
            display: block;
            @include font-size(1.7, 4);
            font-size: 17px 40px;
            padding-right: 10px;
            text-align: right;
            text-decoration: none;
            color: white;
        }
    }
    .device-sidebar > .device-search {

        @media(width <= 720px) {
            display: block;
        }

        position: relative;        
        height: 50px;
        padding: 5px;
        background-color: $black;

        .form-control {
            height: 40px;
            padding-right: 40px;
            background-color: transparent;
            border: solid 1px $grey;
            color: $grey-light;
        }

        button.trnsp {
            background-color: Transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline: none;
            position: absolute;
            top: 8px;
            right: 8px;
            width: 35px;
            height: 35px;

            .icon-search {
                display: block;

                &::before {
                    font-size: 20px;
                    color: #e9e9e9;                
                }
            }
        }
    }
}