#MainPage{
  
  h1{
    @media (width > $monitor) {
      line-height: 72px;
    }
  }

    .home-banner{
        padding-top: 10px;
        padding-bottom: 10px;
        img{
            max-width: 100%;
        }
    }
    .product-sliders{
    @media (width > $monitor) {
      margin-left: -10px;
      margin-right: -10px;
    }   
    }
}