#header {
    .top {
        height: 40px;
        padding: 10px 0;
        background: $white;
        border-bottom: solid 1px $grey-light;
    }

    .nav {        
        &.national-navigation {
            float: left;

            li {
                margin-right: 10px;

                @media(width < 680px) {
                    margin-right: 5px;
                }
            }

            .dropdown-menu {
                left: -10px;
            }
        }

        &.user-navigation {
            float: right;
        }

        > li {
            float: left;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            @media(width < 680px) {
                margin-right: 15px;
            }

            &.account {
                @media(width < 480px) {
                    margin-right: 0;
                }

                > a {
                    z-index: 115;
                }

                span {
                    @media(width < 750px) {
                        display: none;
                    }
                }

                &.dropdown-menu-parent {
                    margin-right: 15px;

                    @media(width < 680px) {
                        margin-right: 10px;
                    }
                }

                .dropdown-menu {
                    z-index: 110;
                }

                .account-logout {
                    display: none;

                    @media(width < 480px) {
                        display: block;
                    }
                }
            }

            &.registration {
                @media(width < 600px) {
                    display: none;
                }
            }

            &.checkout {
                @media(width < 480px) {
                    display: none;
                }
            }

            &.account-login {
                @media(width < 480px) {
                    display: none;
                }
            }

            &.account-logout {
                display: block;

                @media(width < 480px) {
                    display: none;
                }
            }                
        }
        a {
            display: block;
            position: relative;
            z-index: 10;

            &.ico {
                text-decoration: none;

                span {
                    font-size: 12px;
                    font-weight: bold;
                }

                strong {
                    font-weight: $font-regular;

                    &.currency-long {
                        @media(width < 680px) {
                            display: none;
                        }
                    }

                    &.currency-short {
                        display: none;
                        @media(width < 680px) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .dropdown-menu {
        padding: 40px 10px 10px 10px;
        top: -8px;
        right: -5px;
        left: auto;

        li {
            a {
                white-space: nowrap;
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }

    .content {
        background-color: white;
    }

    .cover {        
        @media(min-width: 1025px) {
            display: flex;
            align-items: center;
            height: 130px;            
        }
    }

    .logo {
        height: 100%;
        line-height: 0;
        padding: 20px;
        text-align: center;

        @media(min-width: 1025px) {
            width: calc(30% - 60px);
            text-align: left;
            padding: 20px 0;
            margin-right: 60px;
        }

        a {
            display: inline-block;
            width: 100%;

            @media(min-width: 1025px) {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        img {
            max-height: 80px;

            @media(min-width: 1025px) {
                max-height: 120px;
            }
        }
    }

    .blocks {        
        width: 100%;

        @media(min-width: 1025px) {
            width: 70%;
        }

        @media(max-width: 1025px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: calc(100% + 20px);
            margin: 0 -10px;
        }

        .block-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 10px 0;

            @media(max-width: 1025px) {
                order: 2;
                width: 100%;
                padding: 10px;
                margin: 10px 0 0 0;
                background: $grey-light;
            }
        }

        .block {
            text-align: left;

            .is-icon {
                font-size: 24px;
            }

            &.electronic_goods {
                display: flex;
                margin-left: auto;
                align-items: center;
                text-decoration: none;
                background-color: $grey-light;
                margin-right: 20px;
                border-radius: 3px;

                @media(max-width: 400px) {
                    + .cart {
                        width: auto;
                        flex: 1;
                    }
                }

                @media(max-width: 1025px) {
                    background-color: $white;
                }

                &::before {
                    @extend icomoon;
                    display: inline-block;
                    content: "\e902";
                    background-color: $grey-dark;
                    border-radius: 3px;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    font-size: 24px;
                    padding-top: 13px;
                }

                span {
                    display: inline-block;
                    color: $black;
                    line-height: 1.3em;
                    padding: 0 10px;                    

                    @media(max-width: 500px) {
                        display: none;
                    }
                }

                strong {
                    display: block;
                    font-weight: $font-regular;
                }
            }

            &.cart {
                position: relative;
                z-index: 20;
                width: 240px;

                @media(min-width: 1026px) {
                    width: 280px;
                }

                .inner {
                    position: relative;
                    z-index: 100;
                    text-decoration: none;
                    display: table;
                    width: 100%;
                    background-color: $white;
                    border-radius: 3px;

                    @media(min-width: 1026px) {
                        background-color: $grey-light;
                    }
                }
                .is-icon {
                    float: left;
                    width: 50px;
                    height: 50px;
                    border-radius: 3px;
                }
                .summary {
                    display: table-cell;
                    padding-left: 10px;
                    padding-right: 40px;
                    font-style: normal;
                    vertical-align: middle;
                    width: 100%;
                    color: $black;
                    position: relative;
                    @mixin icon after, "\e617";

                    @media(max-width: 1025px) {
                        padding-right: 20px;
                    }

                    &::after {
                        position: absolute;
                        top: 14px;
                        right: 10px;
                        width: 22px;
                        height: 22px;
                        line-height: 22px;                        
                        text-align: center;
                        background-color: white;
                        border-radius: 100%;
                        background-color: $white;

                        @media(max-width: 1025px) {
                            display: none;
                        }
                    }

                    .monitor {
                        line-height: 1.3em;

                        strong {
                            display: block;
                            font-weight: $font-regular;
                        }                        
                    }

                    span {
                        &.monitor {
                            strong {
                                @media(max-width: 750px) {
                                    display: none;
                                }
                            }
                        }

                        &.price {
                            @media(max-width: 750px) {
                                display: block;
                            }
                        }

                        &.sep {
                            display: inline-block;
                            @media(max-width: 750px) {
                                display: none;
                            }
                        }

                        &.device {
                            display: none;
                        }
                    }
                }

                .cart-preview {
                    display: none;
                    border-radius: 3px;
                    z-index: 90;
                    visibility:hidden;
                    opacity:0;
                    padding-top: 57px;
                    padding-left: 5px;
                    padding-right: 5px;
                    padding-bottom: 5px;
                    position: absolute;
                    top: 0px;
                    margin-top: -3px;
                    margin-left: -3px;
                    background-color: $white;
                    width: 102%;
                    box-shadow: 0px 0px 10px rgba(50, 50, 50, 0.16);

                    @media(width > $monitor) {
                        display: block;
                    }
                    
                    table {
                        width: 100%;

                        tr {
                            position: relative;

                            &:first-child {
                                &::after { display: none; }
                            }

                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 0;
                                left: 0;
                                height: 1px;
                                width: 100%;
                                background: $grey-dark;
                            }

                            &:last-of-type {
                                td {
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                }
                            }
                        }

                        td {
                            vertical-align: middle;                            
                            padding: 5px;

                            &.count {
                                width: 10%;
                                font-size: 12px;
                                vertical-align: top;
                            }
                            &.product {                                
                                strong {
                                    font-weight: $font-medium;
                                }
                            }
                            &.remove {
                                width: 8%;
                                text-align: right;
                            }
                            &.summprice {
                                font-size: 22px 26px;
                                font-weight: $font-medium;

                                small {
                                    display: block;
                                    font-size: 12px 16px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                    .go2cart {
                        a {
                            width: 100%;
                        }
                    }
                }
                &:hover {
                    .cart-preview {
                        visibility: visible;
                        opacity: 1;
                        animation-duration: 0.5s;
                        animation-fill-mode: both;
                        animation-name: fadeInDown;
                    }
                }
            }

            &.search {
                display: none;
                position: relative;
                z-index: 20;
                flex: 1;
                width: 500px;
                margin-right: 20px;

                @media(min-width: 750px) {
                    display: block;
                }
 
                .inner {
                    position: relative;
                }
                .form-control {
                    outline: 0px;
                    border-width: 2px;
                    height: 50px;
                    line-height: 50px;
                }
                .button {                    
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-radius: 3px;
                }
                .btn {
                    font-size: 14px 50px;
                    padding-left: 40px;
                    height: 50px;
                    color: white;
                    border: none;

                    @media(width < 850px) {
                        width: 50px;
                        padding-left: 0;
                    }

                    .is-icon {
                        position: absolute;
                        left: 12px;                        
                        top: 25px;
                        font-size: 18px;
                        font-weight: $font-regular;

                        @media(width < 850px) {
                            left: 50%;
                            margin-left: -12px;
                        }
                    }

                    span {
                        @media(width < 850px) {
                            display: none;
                        }
                    }
                }
            }

            &.levels {
                margin-right: 20px;

                @media(width > $monitor) {
                    display: none;
                }

                a {
                    display: block;
                    text-decoration: none;
                    font-size: 14px 40px;
                    color: white;
                    padding-left: 40px;
                    position: relative;
                    color: black;
                    margin: 0 0 0 -10px;

                    @mixin icon before, "\e600";
                    &:before {
                        position: absolute;
                        left: 10px;
                        font-size: 28px;
                        top: 6px;
                    }
                
                }
            }

            &.mobil {
                padding-right: 2px;

                @media(width > $tablet) {
                    display: none;
                }
                
                a {
                    display: block;
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .mobil-nav {
            @media($mobil) {
                float: left;
                width: 70%;
                .to-right {
                    @mixin clearfix;
                    float: right;
                }
            }
        }
        .links {
            display: flex;
            justify-content: space-between;

            @media(max-width: 1025px) {
                width: 100%;
                justify-content: center;
                order: 1;
            }

            @media(width < 460px) {
                flex-direction: column;
            }

            li {
                margin-right: 20px;

                @media(width < 580px) {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
            
            .link {
                display: flex;
                align-self: center;
                
                li {
                    a {
                        line-height: 30px;
                    }
                }
                &.search {
                    margin-bottom: -5px;

                    @media(width > 460px) {
                        margin: 0 20px 0 0;
                    }
                }
            }
        }
    }
    .mobile-search {
        display: none;
        @media(width > $tablet) {
            display: none;
        }
        &.view {
            display: block;
            @media(width > $tablet) {
                display: none;
            }
        }
        float: left;
        width: 100%;
        .inner {
            position: relative;
            button {
                width: 50px;
                height: 50px;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }
}
