input[type="text"],
.form-control,
.SelectBox {
    width: 100%;
    height: 35px;
    padding: 0 5px;
    border: 1px solid $black-light;
    border-radius: 3px;
    background-image: none;
}

select {
    border: 1px solid $black-light;
}

textarea {
    min-height: 100px;
}

.strong-border {
    border: solid 2px $black-dark;
}

.form-group {
    position: relative;
    /*padding-top: 10px;*/
    margin-bottom: 15px;

    /*&:last-of-type { margin-bottom: 0; }*/

    &.button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        
        @media(width < 450px) {
            flex-direction: column;

            .btn {
                margin-bottom: 25px;
            }
        }

        & * {
            margin-bottom: 0;
        }
    }

    &.password { margin: -5px 0 25px; }

    div.validateError {
        position: absolute;
        right: 0px;
        top: 0px;
        background: #c82026;
        color: white;
        padding: 2px 5px;
        top: 8px;
        border-radius: 3px;
        font-size: 13px;
        &:after {
            top: 100%;
            right: 10px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-top-color: #c82026;
            border-width: 6px;
            margin-left: -6px;
        }
    }
    &.missing {
        &:after {
            @extend icomoon;
            color: #c82026;
            content: "\e618";
            position: absolute;
            right: 0px;
            bottom: 0px;
            right: 15px;
            bottom: 8px;
            font-size: 20px;
        }
    }
    &.valid {
        &:after {
            @extend icomoon;
            color: #6ec01d;
            content: "\e619";
            position: absolute;
            right: 0px;
            bottom: 0px;
            right: 10px;
            bottom: 7px;
            font-size: 20px;
        }
    }
}

.SelectBox {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid $black-light;
    display: inline-block;
    padding: 0;

    select {
        z-index: 1;
        position: relative;
        font-size: 15px;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        outline: none;
        width: 110%;
        height: 33px;
    }
    &:after {
        @extend icomoon;
        color: black;
        content: "\e61a";
        position: absolute;
        right: -7px;
        top: 9px;
        font-size: 17px;
        padding-right: 15px;
    }
    &:hover {
        cursor: hand;
        cursor: pointer;
    }
}

.msg-error {
    color: red;
}