.container-fluid, .container {
    transition: all 1s ease-in-out;
    max-width: 1240px;
    margin: 0 auto;
	@extend .clearfix;

	&.with-padding {
        padding-left: 10px;
        padding-right: 10px;

        @media(min-width: 1025px){
            padding-left: 20px;
            padding-right: 20px;    
        }
	}

    .full-width & {
        max-width: 1240px;
        
        @media(min-width: 1240px){
            max-width: 95%;
        }
    }
}
