
#Registration{

  .missing{
    color: red;
  }
  .row, .form-group{
    lost-utility: clearfix;
  }
  .col-sm-12{
    lost-column: 1/2 2 10px;
  }
  .col-xs-17{
    lost-column: 17/24 2 10px;
  }
  .col-xs-7{
    lost-column: 7/24 2 10px;
  }
  .col-sm-24{
    lost-column: 24/24 2 10px;
  }

  .reg-block, .buttons{
    margin-top: 20px;
  }

  .reg-block {
    h3 { margin-bottom: 10px; }
  }
}