.pagination {
    li {
        display: inline-block;
        border: solid 1px #e9e9e9;
        background-color: #f6f6f6;

        &:hover {
            background-color: #e9e9e9;
        }

        a {
            color: inherit;
            text-decoration: none;
            display: block;
            font-size: 14px 40px;
            width: 40px;
            text-align: center;
        }

        &.active {
            a {
                color: $white;
            }
        }

        &.prev-next {
            line-height: 38px;
            border: solid 1px $black;
            background-color: #e9e9e9;

            &:hover {
                background-color: #ddd;
            }
        }
    }
}