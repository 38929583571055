.format-text {
    padding-top: 20px;
    h3 {
        font-size: 19px;
        line-height: 35px;
        font-weight: bold;
    }
    p {
        line-height: 22px;
        padding-bottom: 20px;
    }
}
.user-data {
    padding-bottom: 20px;
    padding-top: 20px;

    ul {
        padding-bottom: 10px;
        li {
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }

    &.SummaryAddress {
        padding: 0;

        ul {
            padding: 0;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.main-bar {
    .columns-1 & {
        padding: 20px;

        .shop-main-page & {
            padding: 10px;
        }

        .page-Checkout_Delivery &,
        .page-Checkout_Customer &,
        .page-Checkout_Summary & {
            @media(max-width: 1025px) {
                padding: 10px;
            }
        }
    }
}

.single-page {
    max-width: 750px;
}

#CompareListLayout,
#comparelistLayout {
    h1 { margin: .5em 0 1em; }

    h1 + p { margin-bottom: -2em; }

    h2 {
        font-size: 16px 20px;
    }

    a.remove-all {
        color: red;
    }

    .compare-scroller {
        overflow: auto;
        overflow-y: hidden;
    }

    .compare-row {
        th, td {
            background-color: $grey-light;
        }
    }

    table {
        width: 100%;

        th {
            font-weight: $font-medium;
            text-align: left;
        }
        
        th,
        td {
            border-bottom: solid 1px $grey-light;
            width: 200px;
            padding: 5px 10px;
        }
    }
    .center {
        text-align: center;
    }
}
#LoginLayout, #LoginFaultLayout, #LostPasswordLayout, #LoginAndContinueLayout {
    .row {
        max-width: 400px;
    }
}

#AdvancedSearchLayout {
    max-width: 750px;

    legend {
        font-size: 25px;
    }

    .table {
        width: 100%;
        td {
            padding-bottom: 5px;
            padding-top: 5px;
            &.name {
                padding-right: 5px;

                @media(width > 500px) {
                    width: 300px;
                }
            }
        }
    }

    .enhsearch-form {
        padding-top: 30px;
    }
}

#UpdateBrowserLayout {
    .desc {
        padding: 10px 0px;
    }
}

#loyaltyaccountLayout {
    li {
        padding-top: 5px;
        padding-bottom: 5px;
        span {
            display: inline-block;
            padding-left: 5px;
            font-weight: bold;
        }
    }
}

.text-box {
    padding-top: 15px;
    line-height: 26px;
}

.is5table {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    th {
        border-bottom: 2px solid #ddd;
        font-weight: bold;
        padding: 8px;
    }
    td {
        border-top: 1px solid #ddd;
        padding: 8px;
    }
}

/* Customer Admin */

#DashBoard {
    h2,
    h3 {
        margin-bottom: .5em;
    }

    .contact-details {
        li {
            display: flex;
            
            > span {
                &:first-child { min-width: 55px; margin-right: 5px; }
            }            
        }
    }
}

.DashBoardTabs {
    position: absolute;
    top: 20px;
    right: 20px;

    @media(max-width: 500px) {
        position: static;
    }

    ul {
        display: flex;
        flex-direction: column;

        @media(max-width: 500px) {
            margin-bottom: 30px;
        }
    }
}

#BookmarkedProducts,
#Orders {
    table {
        width: 100%;
        margin-top: 20px;
    }
    
    td {
        vertical-align: middle;
    }

    .table-header {
        border-bottom: 2px solid $grey-dark;

        @media(max-width: 850px) {
            display: none;
        }

        td {
            font-weight: 500;
            padding: 0 10px 6px;
        }
        
        .product-view {
            width: 40%;

            > span { padding-left: calc(25% + 5px); }
        }

        .product-price {
            width: 35%;
        }

        .product-option {
            width: 25%;
        }
    }

    .table-body {
        border-bottom: 1px solid $grey-dark;

        @media(max-width: 850px) {
            border: none;

            &:nth-child(2n+1) {
                background-color: $grey-light;
            }
        }

        td {
            padding: 10px 0;
        }

        .product-view {
            width: 40%;

            @media(max-width: 850px) {
                width: 100%;
                float: left;
            }
        }

        .product-price {
            width: 35%;

            @media(max-width: 850px) {
                width: 65%;
                float: left;
            }

            @media(max-width: 500px) {
                width: 100%;
                float: left;
            }
        }

        .product-option {
            width: 25%;

            @media(max-width: 850px) {
                width: 35%;
                float: left;

                ul {
                    justify-content: flex-end;

                    @media(max-width: 500px) {
                        justify-content: center;
                    }
                }

                .option { width: auto; }
            }

            @media(max-width: 500px) {
                width: 100%;
                float: left;
            }
        }

        ul {
            display: flex;
            align-items: center;

            li {
                padding: 0 10px;
            }

            .thumb {
                width: 100px;
            }

            .name {
                width: calc(100% - 100px);
            }

            .price,
            .buy,
            .option {
                width: 50%;
            }

            .buy {
                @media(max-width: 500px) {
                    text-align: right;
                }
            }

            .option {
                > a { white-space: nowrap; }
            }
        }

        .price {
            p {
                margin-bottom: 0;

                + p {
                    font-size: 13px;
                    line-height: 1.2em;
                    display: block;
                    color: $grey;
                }
            }
        }
    }

    .thumb {
        text-align: center;

        img {
            display: inline-block;
            width: auto;
            max-width: 80px;
            max-height: 80px;
        }
    }

    .btn {
        display: inline-block;
        line-height: 30px;
    }
}

#AjaxFilters {
    @extend .clearfix;
    
    @media(max-width: 1025px) {        
        display: none;
        margin-bottom: 1em;
    }

    .btn-filter-close {
        margin-bottom: -1em;
    }
}

.niceScroll {
    clear: both;
}