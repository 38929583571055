.owl-carousel{
  display: none;
  &.owl-loaded{
    display: block;
  }
}

.product-sliders{
    /*@extend .clearfix;
    .slider-box{
        @media (width > $monitor) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }*/ 

    &.two-sliders{
        > div {
            @media (width > $monitor) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: stretch;
                justify-content: space-between;
            }

            > div {
                @media (width > $monitor) {
                    width: calc(50% - 10px);
                }
            }
        }
    }    
    .block-inner{
        position: relative;
        margin-left: -5px;
        margin-right: -5px;
        .product{
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .block-title{
        margin-bottom: 5px;

    }
    .title{
        padding: 12px;
        padding-right: 70px;
        margin-bottom: 5px;
        background-color: $grey-light;

        h4{
            font-weight: $font-medium;
            margin-bottom: 0;
        }

    }
    .block{
        background-color: #FFF;
    }
}

#Viewed {
    padding: 20px 0;
    margin-top: 10px;

    @media(width < 1026px) {
        background: $grey-light;
    }

    .block {
        background-color: transparent;
    }

  .title {
    background-color: transparent;
    padding: 0;

    h4 {
        font-size: 26px;
        line-height: 1.3em;
        font-weight: $font-medium;
        margin-bottom: 0;
    }
  }

}

.owl-nav {
    .owl-prev, .owl-next {
        position: relative;
        display: inline-block;
        @mixin icon before, "";
        
        &:before {
            font-size: 30px;
        }
    }
    .owl-prev {
        &:before{
            content: "\e616";
        }
    }
    .owl-next {
        &:before{
            content: "\e615";
        }
    }
}
.slider-box{
  .owl-nav{
    position: absolute;
    top: -41px;
    right: 16px;
    height: 30px;

    .owl-prev, .owl-next{
      width: 30px;
      height: 30px;
      
      &:before{
          font-size: 30px;
      }
    }
  }
}
#Viewed .product-sliders{
  .owl-nav{
    position: absolute;
    top: -41px;
      right: 6px;
    .owl-prev, .owl-next{
      width: 30px;
      height: 30px;
      &:before{
          font-size: 30px;
      }
    }
  }
}
