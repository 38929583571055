$product-margin-bottom: 30px;
#catalogue {
    padding: 10px;

    @media(max-width: 1025px) {
        margin-top: -38px;

        .shop-main-page & {
            margin-top: 0;
        }
    }

    .page-header {
        h1 {
            margin: .2em 0 .4em;
        }
    }
    .PageCount{
      &.Position_top{
        padding-bottom: 16px;
      }
    }

    #ShowMobileFilter {
        display: none;
    }

    .isFiltered {
        padding-bottom: 10px;
        font-size: 14px;
        .filterValue {
            font-style: italic;
        }
    }

    .SortViewCount {
        position: relative;
        justify-content: space-between;
        margin-bottom: 15px;
        @extend .clearfix;

        @media(min-width: 500px) {
            padding-right: 15px;
            margin-bottom: 0;
        }

        @media(min-width: 780px) {
            margin-bottom: 15px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 40px;
            background-color: $grey-light;
            z-index: 1;
        }
        
        .block {
            &.sort {
                @media(min-width: 500px) {
                    float: left;
                }

                ul.sort {
                    @extend .clearfix;

                    > li {
                        float: left;

                        &.order-type {
                            position: relative;
                            z-index: 5;
                            border-right: solid 1px $white;

                            a {
                                display: block;
                                width: 25px;
                                height: 40px;

                                &:hover {
                                    background-color: #e9e9e9
                                }

                                @media(min-width: 400px) {
                                    width: 30px;
                                }
                            }
                        }

                        &.select {
                            padding-left: 10px;

                            > .select {
                                color: inherit;
                                line-height: 40px;
                                text-decoration: none;

                                span {
                                    font-weight: $font-light;
                                }
                            }

                            .dropdown-menu {
                                padding: 40px 10px 10px 10px;
                                width: 100%;

                                a {
                                    display: block;
                                    color: inherit;
                                    margin-bottom: 4px;
                                }
                            }
                        }
                    }
                }
            }

            &.view-as {
                line-height: 40px;
                text-align: center;

                @media(min-width: 500px) {
                    position: relative;
                    z-index: 5;
                    float: right;
                    margin-right: -8px;
                }

                @media(min-width: 780px) {
                    position: absolute;
                    z-index: 5;
                    display: inline-block;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                li {
                    display: inline-block;
                    padding: 0 8px;

                    &.first {
                        position: relative;

                        &:after {
                            content: "";
                            right: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            position: absolute;
                            width: 1px;
                            height: 12px;
                        }
                    }
                }
            }

            &.count {
                line-height: 40px;
                text-align: center;
                background-color: $grey-light;

                @media(min-width: 500px) {
                    background: none;
                    clear: both;
                }

                @media(min-width: 780px) {
                    position: relative;
                    z-index: 5;
                    float: right;
                    clear: none;
                    text-align: right;
                }
            }
        }
    }

    .PageCount {
        @extend .clearfix;
        .page-total {
            float: left;
            font-size: 14px 40px;
        }
        .pagination {
            float: right;
        }
    }
}
.product-list {
    .product, .next-page-product {
        margin-bottom: $product-margin-bottom;
        width: 100%;
        float: left;

        @media(min-width: $phablet) {
            width: 50%;
        }        
        @media(min-width: $tablet) {
            width: 25%;
        }
        @media(min-width: $monitor) {
            width: 33.33333%;
        }
        @media(min-width: $hd-monitor) {
            width: 25%;
        }
    }

    .full-width & {
        .product, .next-page-product {
            @media(min-width: 1240px) {
                width: 20%;
            }
        }
    }

    .next-page-product {
        a {
            text-decoration: none;
        }

        .product-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.level-description {
    margin-top: 20px;
    margin-bottom: 20px;
    @extend .clearfix;

    img {
        float: left;
        max-width: 300px;
        padding-right: 20px;
    }
}

a.resize {
    display: none;
    text-decoration: none;

    @media(min-width: 1026px) {
        display: block;        
        position: absolute;
        z-index: 5;
        top: 10px;
        right: 10px;
        height: 29px;
        width: 36px;
        color: $black-dark;
        background: $grey-light;
        @mixin icon after, "\e61a";  

        &:after {
            position: absolute;
            bottom: 3px;
            right: 0px;
            left: 0px;
            width: 100%;
            text-align: center;
            font-size: 22px;            
            transform: rotate(90deg);
        }
    }

    .full-width & {
        @mixin icon after, "\e61b";
    }
}

.products-on-page {
    margin-bottom: 15px;

    ul {
        text-align: center;

        li {
            display: inline-block;
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}
