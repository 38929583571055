 .product-list {
 	margin: 0 -5px;
 	/*margin-left: - resolve($columns-margin / 2);
    margin-right: - resolve($columns-margin / 2);*/
 	@extend .clearfix;

 	/*@media(width < $monitor) {
		margin-left: - resolve($columns-margin / 2);
		margin-right: - resolve($columns-margin / 2);
 	}    */
    
    .product {
        padding-left: resolve($columns-margin / 2);
        padding-right: resolve($columns-margin / 2);
    }
}