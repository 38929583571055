$btn: 30px;
$btn-padding: 20px;
$btn-large: 40px;
$btn-large-padding: 20px;
$btn-xlarge: 50px;
$btn-xlarge-padding: 20px;
.btn {
    font-weight: $font-semibold;
    border-radius: 3px;
    -webkit-appearance: none;
    outline: 0;
    border: none;
    vertical-align: middle;
    display: inline-block;
    font-size: 14px 1em;
    height: $btn;
    padding-left: $btn-padding;
    padding-right: $btn-padding;
    text-align: center;
    text-decoration: none;
    color: white;
    background-image: linear-gradient(to top, #96999e, #7e8186);

    /*&.btn-primary {}*/
    &.labeled {
        position: relative;
        padding-left: resolve($btn-padding + $btn);

        .label {
            border-left-radius: 3px;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0px;
            top: 0px;
            width: $btn;
            height: $btn;
        }

        &.labeled-right {
            padding-left: $btn-padding;
            padding-right: resolve($btn-padding + $btn);

            .label {
                border-left-radius: 0px;
                border-right-radius: 3px;
                left:auto;
                right: 0px;
            }
        }
    }

    &:hover {
        cursor: hand; cursor: pointer;
        color: white;
    }

    &.large {
        height: $btn-large;
        font-size: 14px;
        padding-left: $btn-large-padding;
        padding-right: $btn-large-padding;

        &.labeled {
            padding-left: resolve($btn-large-padding + $btn-large);

            &.labeled-right {
                padding-right: resolve($btn-large-padding + $btn-large);
                padding-left: $btn-large-padding;
            }

            .label {
                width: $btn-large;
                height: $btn-large;
            }
        }
    }

    &.xlarge {
        height: $btn-xlarge;
        font-size: 16px 1em;
        padding-left: $btn-xlarge-padding;
        padding-right: $btn-xlarge-padding;

        &.labeled {
            padding-left: resolve($btn-xlarge-padding + $btn-xlarge);

            &.labeled-right {
                padding-right: resolve($btn-xlarge-padding + $btn-xlarge);
                padding-left: $btn-xlarge-padding;
            }

            .label {
                width: $btn-xlarge;
                height: $btn-xlarge;
            }
        }
    }
}

button {
    font-weight: 300;
}

.blank-btn {
    display: block;
    -webkit-appearance: none;
    outline: 0;
    background: transparent;
    padding: 0px;
    border: none;
}


a.btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy-btn {
    padding: 0px;
    border: none;
    display: table;
    border-radius: 3px;

    .icon {
        float: left;
        width: 50px;
        height: 50px;
    }

    span {
        text-transform: uppercase;
        display: table-cell;
        padding-left: 10px;
        padding-right: 10px;
        vertical-align: middle;
        width: 100%;
        font-size: 1.4px 1em;
        color: white;
    }

    &:hover {
        cursor: hand; cursor: pointer;
    }
}

.btn-filter,
.btn-filter-close {    
    float: right;
    font-size: 14px;
    font-weight: 400;    
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 5;
    top: 2px;

    &:hover {
        text-decoration: none;
    }

    @media(min-width: 1025px) {
        display: none;    
    }
}

/*.btn-filter {
    &.btn-link {
        color: $black;
    }    
}*/