#footer {
    .block {
        h4 {
            text-transform: uppercase;
            font-size: 20px 1.2em;
            padding: 0 10px;
            margin: 0 -10px 10px;

            span {
                font-weight: 300;
            }

            a {
                display: none;
                text-decoration: none;
                font-size: 16px 32px;
                padding: 5px;
                position: relative;
                @mixin icon after,
                "\e61a";

                &::after {
                    color: white;
                    right: 15px;
                    top: 10px;
                    position: absolute;
                    font-size: 24px;
                }
            }
        }
    }
}

#FooterTop {
    padding: 20px 0px;
    background: $white;

    .boxes {
        @extend .clearfix;

        @media(min-width: 768px) {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }

        .block {
            @media(min-width: 768px) {
                overflow: hidden;
                width: 50%;
            }

            &:first-of-type {
                @media(min-width: 768px) {
                    margin-right: 20px;
                }
            }

            &:last-of-type {
                @media(min-width: 768px) {
                    margin-left: 20px;
                }
            }

            + .block {
                &::before {
                    content: "";
                    display: block;
                    width: calc(100% + 20px);
                    height: 10px;
                    background: $grey-dark;
                    margin: 20px -10px;

                    @media(min-width: 768px) {
                        display: none;
                    }
                }
            }

            .collapse {
                @media(min-width: 601px) {
                    display: block;
                }
            }
        }
    }

    #NewsRegisterEmail {
        max-width: 400px;
    }
}

#BlogList {
    h5 {
        margin-bottom: 5px;
    }
    p {
        line-height: 1.2em;
    }
}

#FooterBoxes {
    .boxes, .box_row {
      lost-utility: clearfix;
    }
    h4, h5{
      color: white;
    }
    a{
      color: white;
      opacity: 0.7;
      transition: all .3s ease;
      &:hover{
        opacity: 1;
      }
    }
    .boxes{
      padding: 20px 0;
      
      b,
      strong { font-weight: 500; }  
    }

    .block {
        .block-body {
            color: white;

            ul {
                opacity: 0.7;
                margin-top: 10px;
            }

            li {
                margin-bottom: 4px;

                &:last-of-type {
                  margin-bottom: 0;
                }

                a {
                    position: relative;
                    display: inline-block;
                    line-height: 1.3em;
                    padding-left: 18px;                    

                    .text-icon {
                        position: absolute;
                        top: 1px;
                        left: 0;                        
                    }
                }
            }

            p { opacity: 0.7; }
        }
    }
    

    .box_first{ 
      @media (width > 950px){
          lost-column: 10/24;
      }
      .BoxCustommer, .BoxMyAccount{
        @media (width > 550px){
          lost-column: 1/2;
        }
      }
      .BoxMyAccount{
        padding-top: 16px;
        @media (width > 550px){
          padding-top: 0px;
        }
      }
    }

    .box_second{
      padding-top: 16px;
      @media (width > 950px){
            padding-top: 0px;
            lost-column: 14/24;
      }
      .BoxFacebook{
        @media (width > 740px){
            lost-column: 1/2;
        }
        @media (width > 950px){
            lost-column: 6/10;
        }
      }
      .BoxContact{
        padding-top: 16px;
        @media (width > 740px){
            padding-top: 0px;
            lost-column: 1/2;
        }
        @media (width > 950px){
          lost-column: 4/10;
        }
      }
    }
    
    .BoxContact {
        p {
            font-size: 13px;
            margin-bottom: .5em;
        }

        h5 {
            color: white;
            margin-bottom: 0;
        }

        address {
            font-style: normal;
            margin-bottom: 1.2em;

            h5 { opacity: 0.7; }

            p {
                margin-bottom: 0;
            }
        }
    }

    .copyright {
        font-size: 13px;
        line-height: 1.3em;
        text-align: center;
        color: white;
        padding: 10px 0; 
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0.7;

        span {
            opacity: 0.7;
        }
    }
}



    #BlogList .owl-dots{
        text-align: center;
    }
    
    #BlogList .owl-dots .owl-dot{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $grey-dark;
        display: inline-block;
        margin: 0 5px;
    }

    #BlogList .owl-dots .owl-dot.active{
        background: $grey;
    }
    
