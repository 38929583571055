.features{
    padding-top: 15px;
    @extend .clearfix;
    li{
        padding-bottom: 15px;
        @extend .clearfix;
        display: table;
        width: 100%;
        
        .symbol{
            width: 60px;
            height: 60px;
            border-radius: 100%;
            float: left; 
        }
        h4{
            display: table-cell;
            vertical-align: middle;
            font-size: 25px 30px;
            span{
                display: block;
                font-size: 15px 22px;
            }
        }
    }
}