.PageLoading, #InshopAdminRow{
  display: none !important;
}
#page-wrap{
  padding-top: 0px !important;
}

.MainSliderCover{
  padding-bottom: 20px;
}
#MainPage{
    .MainSlider, .MainNoSlider{
      position: relative;
      .MainSlide{
        a{
          text-decoration: none;
        }
        .description{
          top: 10%;
          padding-left: 40px;
          padding-right: 40px;
          @media (width > 600px) {
            padding-left: 0px;
            padding-right: 0px;
            position: absolute;
            left: 70px;
          }
          @media (width > 800px) {
            top: 90px;
          };
          
          h2, p{
              background-color:rgba(255, 255, 255, 0.7);
              @media (width > 600px) {
                padding: 10px;
              }
          }
          h2{
              font-size: 24px 1.1em;
              font-weight: bold;
              margin-bottom: 5px;
              @media (width > 800px) {
                font-size: 34px;
                margin-bottom: 10px;
              }
              
          }
        }
      }
      .owl-prev, .owl-next{
        width: 30px;
        @media (width > 800px) {
          width: 50px;
        }
        
        background: rgba(#FFF, 0.2);
        position: absolute;
        height: 86%;
        top: 7%;
        &:before{
          position: absolute;
          left: 8px;
          top: 50%;
          margin-top: -15px;
          font-size: 21px;
          @media (width > 800px) {
            font-size: 34px;
          }
        }
      }
      .owl-prev{
        left: 5px;
      }
      .owl-next{
        right: 5px;
      }
      &:hover{
        .owl-prev, .owl-next{
          background: rgba(#FFF, 0.7);
        }
      }
    }
}



.slick-slide {
  img{
    max-width: 1200px;
    max-height: 300px;
    margin: 0 auto;
  }
  
}

.MainBxSliderCover{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  background: white;
}

.bxslider{
  &:before{
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    height: 100%;
    width: calc((100% - 680px)/ 2);
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to right,rgba(255,255,255,.8) 0,rgba(255,255,255,0) 100%);
    background-size: 100%;
  }
  &:after{
    content: '';
    position: absolute;
    right: -1px;
    left: auto;
    top: 0;
    height: 100%;
    width: calc((100% - 680px)/ 2);
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to left,rgba(255,255,255,.8) 0,rgba(255,255,255,0) 100%);
    background-size: 100%;
  }
  .slick-prev, .slick-next {
    position: absolute;
    width: 60px;
    height: 100%;
    background: 0 0;
    border-radius: 0;
    border: 0;
    top: 0;
    text-indent: -999em;
    cursor: pointer;
    outline: 0;
    z-index: 9;
    &:before{
      position: absolute;
      top: 50%;
      font-size: 28px;
      text-indent: 0em;
    }
  }
  .slick-prev{
    left: 0;
    @mixin icon before, "\e616";
    &:before{
      left: 0;
    }

  }
  .slick-next {
    right: 0;
    @mixin icon before, "\e615";
    &:before{
      right: 0;
    }
  }
}

.MainBxSliderCover{
  .MainSlide{
    position: relative;
    a{
      text-decoration: none;
    }
    .description{
      top: 10%;
      padding-left: 40px;
      padding-right: 40px;
      position: absolute;
      @media (width > 600px) {
        padding-left: 0px;
        padding-right: 0px;
        
        left: 10px;
      }
      @media (width > 800px) {
        top: 10px;
      };

      p{
        text-shadow: 1px 1px 1px #000000;
        color: white;
        font-weight: bold;
      }
      

      h2{
          font-size: 24px 1.1em;
          font-weight: bold;
          margin-bottom: 5px;
          text-shadow: 1px 1px 1px #000000;
          color: white;
          
      }
    }
  }
  .bx-wrapper{
    margin-bottom: 20px;
  }
}