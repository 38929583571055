.side-bar {
    @extend .clearfix;
    
    .block {
        margin-bottom: 20px;

        h4 {
            font-size: 14px;
            font-weight: $font-medium;
            border-bottom: 1px solid #bbb;
            margin-bottom: 10px;
        }

        &.params {
            position: relative;
            margin-bottom: 40px;

            /*@media(min-width: 1026px) {
                &::before {
                    content: "";
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    bottom: -20px;
                    left: -10px;
                    width: calc(100% + 20px);
                    height: calc(100% + 30px);
                    background: $grey-light;
                }
            }*/

            .block-title {
                position: relative;
            }

            .param-title {
                position: relative;
                font-weight: 400;                
                padding: 6px 0;
                border-bottom: 1px solid $grey-dark;
                margin-bottom: 0;

                a {
                    position: relative;
                    display: block;
                    text-decoration: none;
                    color: inherit;

                    @mixin icon after, "\e617";

                    &::after {
                        font-size: 17px;
                        color: $black;
                        right: 0;
                        top: 2px;
                        position: absolute;
                    }

                    &.open {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            
            }         

            .block-inner {
                position: relative;
                margin-top: -5px;
            }

            .param-inner {
                position: relative;
                font-size: 13px;                
                padding: 10px 0;
                margin-bottom: -6px;

                li {
                    line-height: 15px;
                }
            }
            
            input {
                &[type="checkbox"],
                &[type="radio"] {
                    margin-right: 6px;
                }
            }
            select {
               width: 100%;               
            }
        }
    }

    .block-title {
        a {
            display: block;
            text-decoration: none;
            color: $dark-text-color;
            position: relative;
            @mixin icon after, "\e61a";

            &::after {
                font-size: 23px;
                right: 0px;
                top: -3px;
                position: absolute;
            }
        }
    }

    #SidebarSubLevels {
        display: none;
        margin-bottom: 20px;

        @media(width > $monitor) {
            display: block;
        }

        .block-title {
            h4 {
                padding: 9px 10px;
                color: white !important;
                margin: 0;
            }
        }
    }

    .count-list {
        li {
            .ItemsCount {
                font-weight: 300;
                font-size: 12px;
            }
        }
    }
}

#InfoColumn {
    .main-bar & {
        padding: 10px;
        border-top: 10px solid $grey-dark;

        h4 {
            font-size: 20px;
        }
    }
}