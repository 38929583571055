$gray: #666;
$light-gray: #f2f2f2;
$dark-gray: #666;
$background: #eee;
$text-color: $grey-dark;
$dark-text-color: #232323;
/*$href: #d22e2e;*/
$border-color: #ddd;

$main-gradient: #ef5a5a #d22e2e;
$dark-gradient: #0d6666 #004e4e;
$button-gradient: $dark-gradient;
$light-gradient: $grey-dark $whites;

$footer-bg: $grey-dark;

$columns-margin: 10px;



$font-family: 'Roboto', sans-serif;
$font-headline: 'Roboto', sans-serif;
$font-size: 14px;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

$white: white;
$black: #222;
$black-light: #b2b2b2; /* 30% black*/
$black-dark: #666; /* 60% black */
$grey: #666;
$grey-light: #f2f2f2; /* 5% black */
$grey-dark: #e5e5e5; /* 10% black */
$grey-disabled: #999;
$red-light: #c0392b;
$red-dark: #720b00;
$green: #31b44f;
$green-light: #c1e8ca /* 30% green */