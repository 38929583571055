.is-modal{
  display: none;
  width: 74%;
  margin: 0 0 0 -37%;
  @media (min-height: 1500px) {
    width: 56%;
    margin-left: -28%;
  };
  @media (min-height: 1750px) {
    width: 56%;
    margin-left: -28%;
  };
  @media (min-height: 2000px) {
    width: 56%;
    margin-left: -28%;
  };
  background: #fff;
  border-radius: 3px;
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;

  box-shadow:0px 0px 35px rgba(0,0,0,.3);

  .header, .body, .footer{
    padding: 10px;
  }
  .body{
    height: 333px;
  }
  .footer, .header{
    background-color: $grey-light;
  }
}

.ui.modal>.close{
  top: 0px;
  right: 0px;
  color: black;
}

.ui.modal{
  .next-step{
    padding: 2rem;
    text-align: right;
  }
}

.ui.modal>.content>.description{
  vertical-align: middle;
}

.AjaxPopup{
  .header{
    font-size: 18px;
  }
  .product-img{
    max-width: 100%;
  }
  h4{
    font-size: 21px;
  }
  .next-step{
    padding: 8px;
    text-align: center;
    .btn{
      line-height: 30px;
    }
  }
}

.ui.modal>.content{
  font-size: 16px;
}
.watch_dog_modal{
  .header{
    font-size: 18px !important;
  }
  h3{
    font-size: 22px;
  }
  ul{
    .form-control{
      width: 90px;
      margin-left: 6px;
      margin-right: 6px;
    }
    li{
      margin-top: 6px;
    }
    label{
      display: flex;
      align-items: center;
    }
  }
}

.ui.modal .next-step{
  @media(width > 400px){
    display: flex;
    justify-content: flex-end;
  }

  a.btn{

  }
  a.cancel{
    margin-bottom: 6px;
    @media(width > 400px){
      margin-bottom: 0px;
      margin-right: 8px;
    }

  }
}

.watch_dog_modal_success{
  .content{
    text-align: center;
  }
  .header{
    font-size: 18px !important;
  }
  .suceess{
    font-size: 50px;
    color: green;
    text-align: center;
  }
  h3{
    font-size: 22px;
  }

}
