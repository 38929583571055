.WhispererCover {
    position: absolute;
    background-color: #fff;
    border-radius: 3px;
    margin-top: -53px;
    margin-left: -3px;
    padding-top: 56px;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(50, 50, 50, 0.16);

    li {
        padding: 10px;

        a {
            display: flex;
            align-items: center;            
            color: black;
            text-decoration: none;
            font-size: 14px;
        }

        .img {
            margin-right: 10px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
        }

        img {
            display: inline-block;
            width: auto;
            height: auto;
            max-width: 40px;
            max-height: 40px;
            vertical-align: middle;
        }

        &.active {
            background-color: #EEE;
        }
    }
}