.collapsing {
    position: relative;
    /*height: 0;*/
    overflow: hidden;
    /*transition: height .35s ease;*/
    transition: all .2s linear;
}
#subleve-bar {

	.collapse.selected {
        display: block;
    }
    
    @mixin make-collapsed-menu;

}