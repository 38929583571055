@media(max-width: $monitor) {

    .level-list {
        .device-search { display: none; }

        .collapse.parentOfSelected {
            display: block;
        }

        @mixin make-collapsed-menu;

        .level-1 > li {
            &:hover {
                > a {
                    color: $white;
                    /*background: rgba(114,11,0, .3);*/

                    &.collapse { background: none; }
                }
            }

            /*&:hover,
            &.selected,
            &.selected::after {
                background: $red-light;
            }*/
        }

        a {
            &.sub-menu {
                color: $black;
                border-color: $grey-dark;

                /*&:hover,
                &.selected { color: $red-light; }*/
            }

            &.level-1 {
                color: $white;
                /*background-color: $red-light;
                border-color: $red-dark;*/

                /*&:hover.selected,
                &.selected {
                    background-color: rgba(114,11,0, .6);
                }*/

                &::after, &:hover ::after,
                + ul + .collapse::after,
                &:hover + ul + .collapse::after { color: $white; }

                + ul + .collapse:hover::after { color: $white; }
            }

            &.level-2 {
                background-color: $grey-light;

                &::after,
                + ul + .collapse::after { color: $black; }

                /*&.selected::after,
                &:hover + ul + .collapse::after,
                + ul + .collapse:hover::after { color: $red-light; }*/
            }
        }
    }
}

.levels-container {
    @media(max-width: $monitor) {
        background-color: white;
    }
}
