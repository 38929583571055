.ico-drop-down {
    z-index: 10;
}

.dropdown-menu-parent {
    &.open {
        position: relative;
    }
}

.dropdown-menu {
    display: none;
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    background-color: $white;
    box-shadow: 0px 0px 10px rgba(50, 50, 50, 0.16);

    .open & {
        display: block;
    }
}