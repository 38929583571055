$layout-columns-margin: 20px;

#main {
    > .container {
        border-top: 10px solid $grey-dark;
        border-bottom: 10px solid $grey-dark;

        @media(min-width: 1025px) {
            margin-top: 20px;
            margin-bottom: 20px;
            border: none;
        }

        &.with-padding {
            @media(max-width: 1025px) {
                padding: 0;
            }
        }
    }

    .page-row {
        position: relative;
        overflow: hidden;
        background: white;
        @extend .clearfix;
    }

    &.columns-2 {
        .side-bar {
            padding: 10px;

            @media(min-width: 1025px) {                
                width: 250px;
                float: left;
                padding-right: 0;
            }
        }

        .main-bar {
            width: 100%;

            @media(min-width: 1025px) {
                width: calc(100% - 250px);
                float: right;
                padding: 10px;
            }
        }
    }
}

.PageStickers {
    display: none;
    position: fixed;
    left: 0;
    top: 22%;
    z-index: 200;

    li {
        margin-bottom: -5px;
    }

    img {
        max-width: 84%;
    }

    @media(min-width: 1295px) {
        display: block;
    }
}