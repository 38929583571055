@define-mixin icon $where,  $name {
  &::$where {
    content: $name;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@define-mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@define-mixin make-collapsed-menu {
	li {
		position: relative;
		background-color: $white; /* fix - for using rgba colors on links */
	}

	a {
		display: block;
		color: $black;
		text-decoration: none;
	    padding: 9px 40px 9px 10px;
	    background-color: $grey-light;
		border-bottom: solid 1px $grey-dark;
		@mixin icon after, "\e615";

		&::after {
			position: absolute;
			top: 11px;
            right: 13px;
            font-size: 17px;
			color: $black;
		}

		/*&:hover,
		&:hover::after,
		&:hover + ul + .collapse::after,
		+ ul + .collapse:hover::after {
			color: $red-light;
		}*/

		/* effect - menu :hover arrow rotation */
		&.has-children {
			&::after {
				display: none;
			}

			&:hover + ul {
				+ .collapse::after {
					transform: rotate(-90deg);
				}

				&.in + .collapse::after {
					transform: rotate(90deg);
				}
			}
		}

		&.sub-menu {
			background-color: $white;

			&::after { color: rgba(0,0,0,.3); }
			/*&:hover::after { color: $red-light; }*/
		}

		&.level-3 {
			padding-left: 20px;
		}

		&.level-4 {
			padding-left: 30px;
		}

		&.level-5 {
			padding-left: 40px;
		}

		&.collapse {
			position: absolute;
			width: 40px;
			height: 40px;
			top: 0;
			right: 0;
			padding: 0;
			background: none;
			border: none;

			@mixin icon after, "\e614";

            &::after {
                position: absolute;
                left: 10px;
                top: 11px;
                font-size: 17px;
                transition: .1s linear all;
            }

			&.collapsed {
				&::after {
					content: "\e617";
				}
			}

			/*&:hover {
				&::after {
					color: $red-light;
				}
			}*/
		}

    	/*&.selected {
    		color: $red-light;
    		background-color: rgba(202, 202, 202, 0.1);

			&::after {
				color: $red-light;
			}
    	}*/
	}
}
