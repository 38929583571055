.cookie-line {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    color: white;
    padding: 10px;
    background-color: #3d4951;

    .eu-cookies & {
        display: block;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        font-size: 13px;
        margin-right: 20px;
    }

    a {
      color: white;
    }
}