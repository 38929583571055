.nav-tabs{
    display: table;
    li{
        height: 50px;
        display: table-cell;
        vertical-align: middle;
        a{
            display: block;
            padding-left: 20px;
            padding-right: 20px;
            font-weight: $font-medium;
        }
        /*&.active{
            background-color: #FFF;
            a{
                text-decoration: none;
            }
        }*/
    }
}
.tab-pane{
    display: none;
    &.active{
        display: block;
    }
}
