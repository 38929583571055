@define-extend icomoon {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@define-extend responsive-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
}

@define-extend clearfix:after {
      content: "";
      display: table;
      clear: both;
}
