@media(width > $monitor) {
    .horizontal-levels {
        .levels-container {
            max-width: 1240px;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;

            .full-width & {
                max-width: 95%;
            }
        }
    }

    .level-list {
        &.double-line {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background: rgba(0, 0, 0, 0.4);
            }
        }

        &.effect-fadeDown {
            &.HorizontalMenu {
                li.level-1 {
                    position: relative;
                    &:hover {
                        position: relative;
                        z-index: 100;
                        > a {
                            color: $black;
                        }
                        ul.level-2.sub-menu {
                            display: block;
                            visibility: visible !important;
                            opacity: 1 !important;
                            animation-duration: 0.5s;
                            animation-fill-mode: both;
                            animation-name: fadeInDown;
                        }
                    }
                }
            }
        }

        &.effect-no {
            &.HorizontalMenu {
                li.level-1 {
                    position: relative;
                    &:hover {
                        z-index: 100;
                        > a {
                            position: relative;
                            z-index: 101;
                            color: $black;
                        }
                        /*ul.level-2.sub-menu {
                            display: block;
                            visibility: visible !important;
                            opacity: 1 !important;
                            animation-duration: 0.5s;
                        }*/
                    }
                }
            }
        }

        &.HorizontalMenu {
            .collapse {
                display: block;
            }
            ul {
                @mixin clearfix;
                &.sub-menu {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    min-width: 260px;
                    visibility: hidden;
                    opacity: 0;
                    display: none;
                    position: absolute;
                    left: 0px;
                    top: 100%;
                    background-color: #FFF;
                    padding-left: 10px;
                    padding-right: 10px;
                    z-index: 100;
                    box-shadow: 0px 0px 10px rgba(50, 50, 50, 0.16);
                }
            }
            li {
                &.level-1 {
                    float: left;
                    @mixin clearfix;
                    li {
                        a.level-2 {
                            display: block;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            color: #232323;
                            position: relative;
                            padding-left: 20px;
                            @mixin icon before, "\e615";
                            &:before {
                                color: black;
                                position: absolute;
                                left: 0px;
                                top: 9px;
                            }
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    &:hover {
                        

                        > span {
                            padding: 0 11px;
                        }

                        > .level-1 {
                            > span {
                                display: block;
                                position: relative;
                                z-index: 105;
                                background: rgb(238,238,238);
                                background: -moz-linear-gradient(to top, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 100%);
                                background: -webkit-linear-gradient(to top, rgba(238,238,238,1) 0%,rgba(255,255,255,1) 100%);
                                background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(255,255,255,1) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff',GradientType=0 );
                            }
                        }
                    }

                    &:hover {
                        a.level-1 {
                            &.first {
                                border: none;
                                padding: 0 1px;
                                transform: translateX(-1px);
                            }                            
                        }
                    }
                }
            }

            a {
                display: block;
                text-decoration: none;
                &.level-1 {
                    color: white;
                    height: 50px;
                    font-size: 19px 50px;
                    border-right: solid 1px rgba(0,0,0, 0.4);

                    > span {
                        padding: 0 10px;
                    }

                    &.first {
                        border-left: solid 1px rgba(0,0,0, .4);

                        /*&:hover {
                            border: none;

                            > span {
                                padding: 0 11px;
                            }
                        }*/
                    }
                }
            }

            a.collapse {
                display: none;
            }
        }

        &.DropDownMenu {
            position: relative;
            
            @mixin make-collapsed-menu;

            .collapse.parentOfSelected, .collapse.selected {
                display: block;
            }

            .DropDownShow {
                .first {
                    font-size: 17px 45px;                    
                    font-weight: $font-medium;
                    line-height: 45px;
                    padding: 0;
                    color: white;
                    text-decoration: none;
                    text-transform: uppercase;
                    background: none;
                    border-bottom: none;

                    &:after {
                        display: none;
                    }
                }

                .icon-hamb {
                    font-size: 19px;
                }
            }

            .inner {
                display: none;
                padding-left: 10px;
                padding-right: 10px;
                position: absolute;
                z-index: 200;
                top: calc(100% - 4px);
                min-width: 280px;

                ul.level-1 {
                    position: relative;
                    background-color: white;
                    border: solid 1px #999;

                    &::before {
                        content: "";
                        left: 30px;
                        bottom: 100%;                        
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border: solid transparent;
                        border-color: rgba(242, 242, 242, 0);
                        border-bottom-color: #f2f2f2;
                        border-width: 8px;
                        margin-left: -8px;
                    }

                    > li:first-child {
                        &.selected::before {
                            content: "";
                            left: 30px;
                            bottom: 100%;                        
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border: solid transparent;
                            /*border-color: rgba(249, 235, 233, 0);*/
                            border-bottom-color: $grey-dark;
                            border-width: 8px;
                            margin-left: -8px;
                        }
                    }
                }
            }
        }
    }
}

.DropDownShow {
    display: none;

    @media(width > $monitor) {
        display: block;
    }    
}

@media(width > $monitor) {
    .level-list.DropDownMenu .level-1 > li:hover, .level-list.DropDownMenu .level-1 > li.selected { background: none; }
    .level-list.DropDownMenu .level-1 > li:hover > a { color: inherit; background: $grey-dark; }
    .level-list.DropDownMenu .level-1 > li:hover > a::after { color: inherit; }
    .level-list.DropDownMenu .level-1 > li:hover > a.collapse { background: none; }
    .level-list.DropDownMenu a.level-1:hover.selected, .level-list.DropDownMenu a.level-1.selected { color: inherit; background: $grey-dark; }
    .level-list.DropDownMenu a.sub-menu > span { color: $grey; }
    .level-list.DropDownMenu a.sub-menu:hover > span { color: $black; }
    .level-list.DropDownMenu a.sub-menu:hover, .level-list.DropDownMenu a.sub-menu.selected, .level-list.DropDownMenu a.selected::after { color: inherit; }
    .level-list.DropDownMenu a.sub-menu:hover::after { color: $black; }
    .level-list.DropDownMenu a.collapse:hover::after,
    .level-list.DropDownMenu a.sub-menu:hover + ul + a::after { color: inherit !important; }
    .level-list.DropDownMenu .level-2 a::after { color: rgba(0,0,0,.3); }    
}