*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    background: $grey-dark;
}

body {
    font-family: $font-family;
    color: $black;    
    font-size: $font-size 1.5em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $font-headline;
    color: $dark-text-color;
}

h1 {
    font-size: 32px;
    font-weight: $font-medium;
    line-height: 1.1em;
    margin: .2em 0 .4em;

    @media(max-width: 750px) {
        font-size: 28px;
    }
}

h2 {
    font-size: 24px;
    font-weight: $font-medium;
    margin-bottom: .5em;
}

h3,
h4,
h5 {
    font-weight: $font-medium;
    margin-bottom: .5em;
}

h3 { font-size: 18px; }
h4 { font-size: 16px; }
h5 { font-size: 14px; }

p {
    margin: 0 0 1em 0;

    &:last-of-type { margin-bottom: 0; }
}

strong,
b {
    font-weight: $font-semibold;
}

a {
    &:hover {
        text-decoration: none;
    }
}

input,
textarea,
button,
select,
optgroup,
th, td {
    font-family: $font-family;
}

input {
    &:disabled + input + label { color: $grey-disabled; }
    &:disabled + input + label + small { color: $grey-disabled; }
    &[type="checkbox"],
    &[type="radio"] { margin: 0 2px 0 0; transform: translateY(1px); }
}

input,
textarea {
    font-size: $font-size;
    &:focus { border-color: rgba(192,57,43,.6); } /* $red-light */
}

select {
    padding: 3px 2px;
}

label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 5px;

    .checkout-box & {
        margin-bottom: 0;
    }
}

textarea {
    padding: 5px;
    &.form-control {
        padding: 5px;
    }
}

img,
.responsive-image,
.img-responsive {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.responsive-table {
    width: 100%;

    .td-label {
        display: none;
    }
}

.clearfix, .cf {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?gl1bdu');
  src:  url('../fonts/icomoon.eot?gl1bdu#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?gl1bdu') format('truetype'),
    url('../fonts/icomoon.woff?gl1bdu') format('woff'),
    url('../fonts/icomoon.svg?gl1bdu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

[class^="icon-"],
[class*=" icon-"],
.ico-is5,
.is-icon,
.text-icon {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}