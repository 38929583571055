.is-icon {
    position: relative;
    text-decoration: none;
    transform-style: preserve-3d;
    &:before {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
    }
    &.icon-search {
        &:before {
            content: "\e60d";
            color: white;
        }
    }
    &.icon-user {
        &:before {
            content: "\e60b";
            color: white;
        }
    }
    &.icon-log-in {
        &:before {
            content: "\e60a";
            color: white;
        }
    }
    &.icon-log-out {
        &:before {
            content: "\e609";
            color: white;
        }
    }
    &.icon-cart {
        &:before {
            content: "\e60e";
            color: white;
        }
    }
    &.icon-remove {
        &:before {
            content: "\e603";
            color: white;
        }
    }

    &.icon-preview {
        &:before {
            content: "\e610";
            color: white;
        }
    }
    &.icon-next {
        &:before {
            content: "\e60f";
            color: white;
        }
    }
    &.icon-send {
        &:before {
            content: "\e613";
            color: white;
        }
    }
    &.icon-send {
        &:before {
            content: "\e613";
            color: white;
        }
    }
    &.icon-gift {
        &:before {
            content: "\e99f";
            color: white;
        }
    }
}

.text-icon {
    text-decoration: none;
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    &:before {
        vertical-align: -10%;
        font-size: inherit;
        display: inline-block;
        width: 1.07142857em;
        text-align: center;
        margin-right: 5px;
    }
    &.icon-search {
        &:before {
            content: "\e60d";
        }
    }
    &.icon-compare {
        &:before {
            content: "\e606";
        }
    }
    &.icon-watch_dog {
        &:before {
            content: "\e900";
        }
    }
    &.icon-bookmark {
        &:before {
            content: "\e608";
        }
    }

    &.icon-remove {
        &:before {
            content: "\e603";
            color: $red-dark;
            font-weight: 300;
        }
    }
    &.icon-back {
        &:before {
            content: "\e610";
        }
    }
    &.icon-hamb {
        &:before {
            content: "\e600";
        }
    }
    &.icon-arrow-right {
        &:before {
            content: "\e615";
        }
    }
    &.icon-send {
        &:before {
            content: "\e613";
        }
    }
    &.icon-sucess {
        &:before {
            content: "\e619";
        }
    }
    &.icon-gift {
        &:before {
            content: "\e99f";
            color: white;
        }
    }
}

.icomoon {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ico {
    text-decoration: none;
    position: relative;
    transform-style: preserve-3d;
    &:after {
        position: absolute;
        right: 0px;
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        top: 50%;
        transform: translateY(-50%);
    }
    &:before {
        position: absolute;
        right: 0px;
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        top: 50%;
        transform: translateY(-50%);
    }
    &.ico-drop-down {
        outline: 0;
        padding-right: 15px;
        &:after {
            content: "\e61a";
            color: black;
        }
        .open & {
            &:after {
                content: "\e61b";
                color: black;                
            }
        }
        &.select {
            padding-right: 20px;
        }
        .select & {
            &:after {
                right: 4px;
        }
    }
    }
    &.ico-sort-desc,
    &.ico-sort-asc {
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            font-size: 20px;
            color: black;
        }
    }
    &.ico-sort-desc {
        &:after {
            content: "\e614";
        }
    }
    &.ico-sort-asc {
        &:after {
            content: "\e617";
        }
    }
    &.ico-add2cart {
        &:before {
            position: absolute;
            content: "\e60e";
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            font-size: 20px;
            left: 0px;
        }
    }
    &.icon-next {
        &:before {
            position: absolute;
            content: "\e60f";
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            width: 100%;
            left: 0px;
        }
    }
    &.icon-preview {
        &:before {
            position: absolute;
            content: "\e610";
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            width: 100%;
            left: 0px;
        }
    }
    &.icon-trash {
        &:before {
            position: absolute;
            content: "\e612";
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            width: 100%;
            left: 0px;
        }
    }
    &.ico-next {
        &:before {
            position: absolute;
            content: "\e615";
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            width: 100%;
            left: 0px;
        }
    }
    &.ico-prev {
        &:before {
            position: absolute;
            content: "\e616";
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            width: 100%;
            left: 0px;
        }
    }
    &.icon-gift {
        &:before {
            content: "\e99f";
            color: white;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 20px;
            width: 100%;
            left: 0px;
        }
    }
}
