#viewpriceListLayout {
    .PriceListItem {
        padding-bottom: 8px;
        padding-top: 8px;
        border-bottom: 1px solid #EFEFEF;
        &:last-of-type {
            margin-bottom: 30px;
        }
        .signs {
            li {
                padding-bottom: 3px;
                display: inline-block;
                span {
                    font-size: 12px;
                    padding: 3px;
                    border-radius: 3px;
                }
            }
            padding-bottom: 3px;
        }
        @extend .clearfix;
        .Thumb {
            width: 100%;
            float: left;
            @media(width > 400px) {
                width: 40%;
            }
            @media(width > 700px) {
                width: 20%;
            }
        }
        .ItemDetails {
            width: 100%;
            float: left;
            @media(width > 400px) {
                width: 60%;
            }
            @media(width > 700px) {
                width: 50%;
            }
        }
        .BuyAndPrice {
            width: 100%;
            float: left;
            @media(width > 700px) {
                width: 30%;
            }
            ;
        }
        .Thumb {
            img {
                width: 120px;
                margin: 0 auto;
            }
        }
        .ItemDetails {
            padding-left: 5px;
            ul.header {
                padding-bottom: 10px;
                li {
                    display: inline-block;
                    font-size: 13px 1.2em;
                    margin-right: 10px;
                }
            }
            ul.dots {
                padding-bottom: 10px;
                li {
                    font-size: 13px;
                    list-style-type: disc;
                    list-style-position: inside;
                }
            }
            .Description {
                font-size: 13px;
            }
        }
        .BuyAndPrice {
            @extend .clearfix;
            padding-top: 10px;
            @media(width > 400px) {
                text-align: right;
            }
            @media(700px) {
                text-align: left;
                padding-top: 0px;
                padding-left: 15px;
            }
            .PriceDetails {
                margin-bottom: 10px;
                
                @media(max-width: 700px) {
                    float: none;
                }

                p {
                    margin-bottom: 0;
                }

                .withoutTax {
                    font-size: 13px;
                    > b { font-weight: $font-regular; }
                }

                .old-price {
                    text-decoration: line-through;
                    white-space: nowrap;
                    /*font-size: 14px;*/
                }

                small {
                    display: block;
                    font-size: 13px;
                }
            }
            .Add2Cart {
                display: inline-block;
                /*white-space: nowrap;*/

                @media(width > 700px) {
                    float: none;
                }
                .form-control {
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    text-align: center;
                }
                &.WithCount {
                    .btn { margin-left: 10px; }
                }
            }
        }
    }
}