html {
  height: 100%;
}
body {
  min-height: 100%;
}
@import "LiteV1/postcss/helpers/variables";
@import "LiteV1/postcss/helpers/breakpoints";

@import "LiteV1/postcss/base/reset";
@import "LiteV1/postcss/base/typography";
@import "LiteV1/postcss/helpers/extends";
@import "LiteV1/postcss/helpers/mixins";

@import "Shared/main-sliders";

@import "LiteV1/postcss/layout/layout";
@import "LiteV1/postcss/layout/header";
@import "LiteV1/postcss/layout/level-list-desktop";
@import "LiteV1/postcss/layout/level-list-sidebar";
/*@import "LiteV1/postcss/layout/mobile-sidebar";*/
@import "LiteV1/postcss/layout/grid";
@import "LiteV1/postcss/layout/custom-pages";
@import "LiteV1/postcss/layout/mobile-sidebar";
@import "LiteV1/postcss/layout/side-bar";
@import "LiteV1/postcss/layout/footer";

@import "LiteV1/postcss/components/bootstrap";
@import "LiteV1/postcss/components/form";
@import "LiteV1/postcss/components/buttons";
@import "LiteV1/postcss/components/product-list";
@import "LiteV1/postcss/components/product";
@import "LiteV1/postcss/components/breadcrumb";
@import "LiteV1/postcss/components/subcategories";
@import "LiteV1/postcss/components/pager";
@import "LiteV1/postcss/components/icons";
@import "LiteV1/postcss/components/drop-downs";
@import "LiteV1/postcss/components/news-taking";
@import "LiteV1/postcss/components/features";
@import "LiteV1/postcss/components/tabs";
@import "LiteV1/postcss/components/product-sliders";
@import "LiteV1/postcss/components/effects";
@import "LiteV1/postcss/components/modal";
@import "LiteV1/postcss/components/whisperer";
@import "LiteV1/postcss/components/cookies";
@import "LiteV1/postcss/components/sidebar";


@import "LiteV1/postcss/pages/main";
@import "LiteV1/postcss/pages/catalogue";
@import "LiteV1/postcss/pages/price-list";
@import "LiteV1/postcss/pages/product-detail-simple";
@import "Shared/cart";
@import "LiteV1/postcss/pages/checkout";
@import "LiteV1/postcss/pages/registration";
@import "LiteV1/postcss/pages/orders";
