#OrdersLayout{
  .single-page{
    max-width: 100%;
  }
}

#OrdersLayout{
  table{
    margin-top: 6px;
    margin-bottom: 6px;
    width: 100%;
    td, th{
      vertical-align: middle;
      padding: 4px;
    }

    td{      
      @media(width < 600px){
        float: left;
        width: 50%;
        &.Orders_Index{
          width: 100%;
          background-color: #f2f5f8;
          text-align: center;
        }
      }
      @media(width < 400px){
        width: 100%;
      }
    }
    .price {
      p {
        margin-bottom: 0;

        + p {
            font-size: 13px;
            line-height: 1.2em;
            display: block;
            color: $grey;
        }
      }
    }

  }
  .tables-header{
    background-color: #f2f5f8;
    font-weight: 600;
    display: none;
    @media(width > 600px){
      display: table-row;
    }
  }
}

#BuyedFiles{
  padding-bottom: 60px;
  ul{
    li{
      padding-top: 6px;
      padding-bottom: 6px;
      border-bottom: solid 1px #EEE;
    }
  }
}

#OrderDetailLayout{
  table{
    width: 100%;
  }
  .details{
    padding-top: 8px;
    padding-bottom: 8px;
  }
  td, th{
    vertical-align: middle;
    padding: 4px;
  }
  td{
    
    @media(width < 600px){
      float: left;
      width: 100%;
    }
  }
  .table-body{
    border-bottom: solid 1px #EEE;
  }
  .MobileDesc{
    display: block;
    font-weight: 600;
    @media(width > 600px){
      
      display: none;
    }
  }
  .table-header{
    background-color: #f2f5f8;
    font-weight: 600;
    display: none;
    @media(width > 600px){
      display: table-row;
    }
  }
}