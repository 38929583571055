.btn.labeled{
  &:hover{
    .ico-add2cart{
      &:before{
        animation: toBottomFromTop 0.3s forwards;
      }
    }
    .icon-next{
      &:before{
        animation: toRightFromLeft 0.3s forwards;
      }
    }
  }
}

.ico-sort-desc{
  &:hover{
    &:after{
      animation: toTopFromBottom 0.3s forwards;
    }
  }
}

.ico-sort-asc{
  &:hover{
    &:after{
      animation: toBottomFromTop 0.3s forwards;
    }
  }
}

  


// toBottomFromTop
@keyframes toBottomFromTop {
  49% {
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}


// toTopFromBottom
@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

// toRightFromLeft
@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

