.left-bar {
    .product-detail-page& {
        @media(--device-viewport) {
            display: none;
        }
    }
}

#product {
    background-color: #fff;

    .SelectablePropertiesList{
      .SelectBox{
        max-width: 150px;
      }
    }

    .container.with-padding { padding: 0; }

    .product-boxes,
    .product-footer .tabs-linked {
        @extend .clearfix;
/*        margin-left: -10px;
        margin-right: -10px;*/
    }
    /* RESPONSIVITA */
    .product-box {
        /*@media(width > $tablet) {
            padding-left: 10px;
            padding-right: 10px;
        }*/
        &.box-thumbnail {
            width: 100%;
            @media(width > $tablet) {
                float: left;
                width: 50%;
            }
            @media(width > $monitor) {
                width: 33.333%;
            }
        }
        &.box-headline {
            width: 100%;
        }
        &.box-details {
            @media(width > $tablet) {
                width: 50%;
                float: left;
                .inner {
                    padding-left: 20px;
                }
            }
            @media(width > $monitor) {
                width: 66.66667%;
                .inner {
                    padding-left: 40px;
                }
            }
        }
    }
    /* LAYOUT */
    .product-box {
        &.box-thumbnail {
            .thumb {
                a {
                    text-decoration: none;
                }
                min-height: 100px;
                img {
                    max-height: 400px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .maximize {
                font-size: 12px;
                text-align: center;
                text-decoration: none;
                background: rgba(black, 0.2);
                margin-top: 20px;
            }
            .gallery {
                padding-top: 60px;
                position: relative;
                .owl-carousel {
                    position: relative;
                }
                .owl-stage-outer {}
                .owl-item {
                    padding-left: 2px;
                    padding-right: 2px;
                    display: table;
                    .gallery-img {
                        text-align: center;
                        border: solid 1px $border-color;
                        height: 90px;
                        display: table-cell;
                        vertical-align: middle;
                        img {
                            margin: 0 auto;
                        }
                    }
                }
                .owl-controls {
                    text-align: center;
                    .owl-nav {
                        position: absolute;
                        width: 100%;
                        top: -46px;
                    }
                    .owl-prev,
                    .owl-next {
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        border: solid 1px $border-color;
                    }
                    .owl-prev {
                        margin-right: 2px;
                    }
                    .owl-next {
                        margin-left: 2px;
                    }
                }
                .owl-dots {
                    text-align: center;
                    padding-top: 10px;
                    .owl-dot {
                        display: inline-block;
                        padding: 5px;
                        span {
                            display: block;
                            border-radius:100%;
                            width: 8px;
                            height: 8px;
                            background-color: $border-color;
                        }
                        &.active {
                            span {
                                background-color: $dark-text-color;
                            }
                        }
                    }
                }
            }
        }
        /*&.box-headline {
            .inner {
                padding-top: 16px;
                padding-bottom: 16px;
            }
            margin-bottom: 16px;
        }*/
        &.box-details {
            .signs {
                li {
                    display: inline-block;
                    span {
                        display: block;
                        border-radius: 3px;
                        padding: 3px;
                        font-size: 13px 1em;
                    }
                }
            }
            .data-row {
                font-size: 14px 30px;
                @extend .clearfix;
                li {
                    float: left;
                    padding-right: 15px;
                }
            }
            .short-description {
                padding-top: 20px;
                padding-bottom: 30px;
            }
        }
        &.box-buy {
            padding-top: 10px;
            padding-bottom: 10px;
            .select-variant {
                label {
                    display: block;
                    font-size: 16px 24;
                    font-weight: $font-medium;
                }
                @media(width > $monitor) {
                    width: 50%;
                }
            }
            .block {
                &.buy {
                    .with-sidebars & {
                        margin-top: 10px;
                        padding-top: 10px;
                    }
                }
            }
            .price {
                display: flex;
                align-items: center;
                li {
                    &.view-price {
                        font-size: 36px 42px;
                        padding-right: 6px;
                    }
                    &.discount-price {
                        font-size: 14px;
                        text-decoration: line-through;
                    }
                    &.discount-value {
                        font-weight: $font-medium;
                        padding-left: 15px;
                    }
                    &.LoyaltyPrice {
                        padding-top: 10px;
                        span {
                            font-weight: $font-medium;
                        }
                    }
                }
            }
            .SelectablePropertiesList{
              label{
                display: block;
                padding-bottom: 4px;
              }

            }
            .add2cart {
                @extend .clearfix;
                margin-top: 10px;
                margin-bottom: 10px;
                .toCount {
                    float: left;
                    width: 50px;
                    height: 50px;
                    li {
                        a {
                            display: block;
                            height: 25px;
                            line-height: 25px;
                            @extend .icomoon;
                            text-decoration: none;
                            text-align: center;
                            color: black;
                        }
                        &:first-child {
                            border-bottom: solid 1px #dfe2e3;
                            a {
                                height: 24px;
                                line-height: 24px;
                            }
                        }
                    }
                }
                .count-field {
                    @extend .strong-border;
                    width: 50px;
                }
                .ShowMU {
                    width: 25px;
                    text-align: center;
                    display: inline-block;
                    font-size: 18px 50px;
                }
                .count2cart {
                    padding-right: 10px;
                    float: left;
                    input {
                        text-align: center;
                        height: 50px;
                        line-height: 50px;
                    }
                }
                button {
                    float: left;
                }
                .ButtonAddForPoints {
                    float: left;
                    padding-left: 10px;
                }
            }
            .AddTo {
                font-size: 14px 50px;
                float: left;
                padding-left: 20px;
                a {
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }
    }
    .ComponentsList,
    .configuration {
        h3 {
            font-size: 26px 1.3em;

            @media(max-width: 750px) {
                font-size: 22px;
            }
        }
    }
    .componentgroup-name {
        font-weight: $font-medium;
        line-height: 30px;

        + div {        
            max-width: 400px;
            line-height: 25px;
            display: flex;
            justify-content: space-between;

            select {
                width: 86%;
                    @media(width > 400px) {
                        width: 90%;
                    }
            }
        }
    }
    .configure-button {
        padding-top: 10px;
    }
    table.ComponentsList {
        width: 100%;
        strong {
            font-weight: 600;
        }
        td {
            border: solid 1px $border-color;
            padding: 5px;
        }
        .table-list-header {
            td {
                background-color: #f6f6f6;
            }
        }
    }
    .product-footer {
        /*#product-tabs,
        .SelectLinked {
            @media(width > $tablet) {
                padding-left: 10px;
                padding-right: 10px;
            }*/
            .owl-controls {
                position: absolute;
                top: -41px;
                right: 0;
            }
        }
/*        .SelectLinked {
            width: 100%;
            float: left;
            &.columns-1 {
                width: 33.3333%;
            }
        }*/
        #product-tabs {
            margin-top: 40px;

            .hidden-xs {
                @media(width < 600px) {
                    display: none;
                }
            }
            .visible-xs {
                @media(width > 600px) {
                    display: none;
                }
            }            
            .form-tab {
                max-width: 540px;
                textarea {
                    height: 140px;
                }
            }
            .tab-content {
                padding: 20px;
                background: $grey-light;

                @media(width < 600px) {
                    display: none;
                }
            }
            .parameters-wrap { margin: 0 -10px; }
            table.parameters {
                width: 100%;
                tr {
                    &.property-title {
                        td {
                            background-color: $grey-dark;

                            @media(width < 600px) {
                                background-color: $grey-light;
                            }
                        }
                        &:first-of-type {
                            td { border: none; }
                        }
                    }
                }
                td {
                    padding: 5px 10px;
                    border-bottom: solid 1px rgba(0,0,0,.15);

                    @media(width < 600px) {
                        border-bottom: solid 1px $grey-dark;
                        background: none;
                    }

                    &.property-name {
                        padding-right: 30px;
                        min-width: 200px;
                        @media(width < 600px) {
                            min-width: 0;                            
                        }
                    }
                    &.property-value {
                        /*font-weight: $font-medium;*/
                        min-width: 80%;
                        @media(width < 600px) {
                            min-width: 0;
                        }
                    }
                }
            }
        }
        .tabs-cover {
            .nav-tabs {
                width: 100%;
            }
            .panel-default {
                padding-bottom: 3px;
            }
            .panel-body {
                padding: 10px;
            }
            .panel-heading {
                a {
                    text-decoration: none;
                    display: block;
                    color: black;
                    padding: 12px;
                    background-color: $grey-dark;
                    position: relative;
                    @mixin icon after, "\e61a";
                    &:after {
                        right: 15px;
                        top: 10px;
                        position: absolute;
                        font-size: 24px;
                    }
                    &.collapsed {
                        background-color: $grey-light;
                    }
                }
            }
            li {
                border-right: solid 1px white;
                background-color: $grey-dark;

                > a {
                    color: $black;
                }

                &.active {
                    background: $grey-light;
                    > a {                        
                        text-decoration: none;
                    }
                }
                
                &.last {
                    border-right: none;
                }
            }
        }
        .SelectLinked {
            margin: 20px 0;

            &:last-of-type {
                margin-bottom: 0;
            }

            .block-title {
                h3 {
                    font-size: 26px 1.3em;
                }
            }
        }
    }
    .social {
        height: 30px;
        .soc {
            display: inline-block;
            &:nth-of-type(1) > iframe {
                width: 135px !important;
                padding-top: 5px;
            }                      
        }
    }
    .amount-price-detail {
        padding-bottom: 15px;
        h4 {
            font-size: 24px 1.2em;
        }
        table {
            th,
            td {
                padding: 10px;
                border-bottom: solid 1px #dfe2e3;
            }
            th {
                font-weight: $font-medium;
            }
            tr {
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
            span {
                &.withVat {
                    font-weight: $font-medium;
                }
                &.withoutVat {
                    display: inline-block;
                    padding-left: 5px;
                    font-size: 12px 1em;
                }
            }
        }
    }
/*}*/