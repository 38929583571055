#breadcrumb {    
    margin: 0 -2px 1.6em -2px;    

    @media(max-width: 1025px) {
        overflow: hidden;
        padding-right: 20px;
    }

    .breadcrumb {
        .inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        span {
            display: inline-block;
            padding: 0 2px;
            
            @media(max-width: 680px) {
                display: none;
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            &:nth-last-child(3) {
                @media(max-width: 680px) {
                    display: inline-block;
                    margin: 0;

                    > a {
                        margin-left: -4px;

                        &::before {
                            display: inline-block;
                            font-family: 'icomoon';
                            content: "\e615";
                            transform: rotate(-180deg) translateY(-2px);
                        }
                    }
                }
            }                
        }
    }

    .shop-main-page & {
        display: none;
    }
}