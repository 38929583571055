.NewsTaking{
    .form-group div.validateError{
        top: -29px;
    }
    .form-group.missing:after, .form-group.valid:after{
        right: 120px;
    }
    p{
        font-size: 14px 18px;
        margin-bottom: 10px;
    }
    .captcha{
        @extend .clearfix;
        li{
            float: left;
            span{
                display: block;
                float: left;
                margin-right: 10px;
            }
        }
        .captchaRefresh{
            line-height: 35px;
            height: 35px;
        }
        img{
            height: 35px;
        }
        .form-control{
            line-height: 35px;
            height: 35px;
            max-width: 100px;
        }
    }
    .email{

        position: relative;
        .form-control{
            line-height: 35px;
            height: 35px;
        }
        .btn{
            position: absolute;
            top: 0px;
            right: -2px;
            height: 35px;
            color: white;
        }
    }
}