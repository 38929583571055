#CheckoutSteps {
    table {
        border-spacing: 0;
    }
    .InSteps {
        display: table;
        width: 100%;
        overflow: hidden;
        a, span {
            display: table-cell;
            vertical-align: middle;
            text-decoration: none;
            font-weight: $font-medium;
            height: 50px;
            line-height: 1.3em;
            padding-left: 50px;
            position: relative;
            color: $black;
            /*background: #dfe2e3;*/
            background: $grey-dark;

            &:first-child {
                padding-left: 10px;
            }
            &:before {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid $white;
                position: absolute;
                top: 50%;
                margin-top: -50px;
                /*arrow width*/
                margin-left: 4px;
                left: 100%;
                z-index: 1;
            }
            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid $grey-dark;
                position: absolute;
                top: 50%;
                margin-top: -50px;
                left: 100%;
                z-index: 2;
            }
            &.active {
                color: white;
                background: $grey;
                &:after {
                    border-left-color: $grey !important;
                }
            }
            @media(max-width: 400px) {
                font-size: 14px;
                padding-left: 38px;
            }
        }
    }
}
#InshopCheckout {
    table {
        border-spacing: 0;
    }

    .delivery-header {
        margin-bottom: 20px;
    }
    .mobile-tabs {
        display: none;
        /*@media(width > 600px) {
            display: none;
        }*/
        a, span {
            text-decoration: none;
            font-size: 22px 50px;
            display: block;
            padding-left: 10px;
            position: relative;
            @mixin icon after, "\e61a";
            &:after {
                color: white;
                right: 15px;
                top: 10px;
                position: absolute;
                font-size: 31px;
            }
        }
        .tab {
            margin-bottom: 2px;
            &.after-active {
                span, a {
                    background-color: $dark-gray;
                    color: white;
                }
            }
            &.active {
                span, a {
                    background-color: #f4f5f6;
                    color: black;
                    @mixin icon after, "\e61b";
                    &:after {
                        color: black;
                    }
                }
            }
            &.before-active {
                span, a {
                    background-color: #dfe2e3;
                    color: black;
                    &:after {
                        color: black;
                    }
                }
            }
        }
        &.top {
            padding-top: 10px;
            a, span {}
        }
        &.bottom {
            padding-top: 10px;
            padding-bottom: 30px;
        }
    }
    .InshopCheckout {
        margin-top: 30px;
        @extend .clearfix;
        margin-left: -10px;
        margin-right: -10px;
    }

    .left-side, .summary, .buttons-navigation {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .left-side {
        @media(max-width: 750px) {
            .buttons-navigation {
                padding-left: 0;
                padding-right: 0;
            }            
        }
    }

    .left-side {
        h3 {
            margin-bottom: 10px;
        }

        .btn { margin-top: 20px; }

        @media(width > 750px) {
            width: 50%;
            float: left;
        }
    }
    .summary {
        margin-top: 20px;

        @media(width > 750px) {
            width: 50%;
            float: left;
            margin-top: 0;
        }
    }
    .buttons-navigation {
        @extend .clearfix;
        @media(width > 750px) {
            width: 100%;
            float: left;
        }
        @media(max-width: 400px) {
            .btn.xlarge.labeled .label {
                width: 30px;
            }
            .btn.xlarge.labeled {
                padding-left: 50px;
            }
            .btn.xlarge.labeled.labeled-right {
                padding-right: 50px;
                padding-left: 20px;
            }
        }
        padding-top: 20px;
        .preview-button {
            float: left;
        }
        .next-button {
            float: right;
        }
    }
    .show-on-mobile {
        padding-bottom: 10px;
        @media(width > 750px) {
            display: none;
        }
    }
    .left-side {
        .is-title {
            background-color: #f4f5f6;
            padding: 15px;
            &:before {
                font-family: 'icomoon';
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e61a";
            }
            &.active {
                background-color: #dfe2e3;
                &:before {
                    content: "\e61b";
                }
            }
            &:hover {
                cursor: pointer;
            }
        }
        .delivery-content {
            padding-bottom: 10px;
            display: none;
            border-right: solid 1px;
            border-left: solid 1px;
            border-bottom: solid 1px;
            border-color: #dfe2e3;
            &.active {
                display: block;
                margin-bottom: 1px;
            }
        }
        .select2 {
            width: 80% !important;
        }
        .payment-select {
            border: solid 1px #dfe2e3;
        }
        .row {
            @extend .clearfix;
            margin-left: -10px;
            margin-right: -10px;
        }
        .col-sm-12 {
            padding-left: 10px;
            padding-right: 10px;
            @media(width > $monitor) {
                width: 50%;
                float: left;
            }
        }
        .col-xs-17 {
            padding-left: 10px;
            padding-right: 10px;
            width: 70.83333%;
            float: left
        }
        .col-xs-7 {
            padding-left: 10px;
            padding-right: 10px;
            width: 29.16667%;
            float: left
        }
        .col-sm-24 {
            padding-left: 10px;
            padding-right: 10px;
            @media(width > $monitor) {
                width: 100%;
                float: left;
            }
        }
        ul.select {
            li {
                padding: 5px 10px 5px 10px;
                border-bottom: solid 1px $grey-dark;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    .address {
        background-color: #FFF;
        h2,
        h3 {
            margin-bottom: 10px;
        }
    }
    .summary {
        .summary-block {
            background-color: $grey-dark;
            padding: 5px;
            margin-top: 10px;
        }

        .cart-list {
            @extend .clearfix;

            h5 {
                float: left;        
                width: calc(100% - 160px);
                padding-right: 20px;

                @media(width <= 480px ) {
                    float: none;
                    width: auto;
                    padding-right: 0;
                    margin-bottom: 5px;
                }
            }

            p {
                float: right;
                width: 160px;

                @media(width <= 480px ) {
                    float: none;
                    width: auto;
                }
            }
        }

        table {
            width: 100%;
            background-color: #FFF;
            small {
                display: block;
                font-size: 14px;
            }
            th {
                padding: 5px;
                height: 50px;
                font-size: 18px;
                color: black;
            }
            td {
                padding: 10px;
                border-bottom: solid 1px $grey-dark;

                &.count {
                    vertical-align: top;
                    text-align: right;                    
                    white-space: nowrap;
                    padding-right: 0;

                    @media(width <= 480px ) {
                        width: auto;                        
                    }
                }
                &.pay-detail {
                    @extend .clearfix;

                    h4 {
                        font-size: 18px;
                        float: left;
                        width: calc(100% - 160px);
                        padding-right: 20px;

                        @media(width <= 480px ) {
                            float: none;
                            width: auto;
                            padding-right: 0;
                            margin-bottom: 5px;
                        }

                        span {
                            font-size: 14px;
                            font-weight: $font-regular;
                            display: block;                            
                        }
                    }
                    p {
                        float: right;
                        width: 160px;

                        @media(width <= 480px ) {
                            float: none;
                            width: auto;
                        }
                    }
                }
                small {
                    font-size: 13px;
                    color: $grey;
                }
            }
            
            .product:last-of-type {
                td {
                    border: none;
                }
            }    

            .footer {                
                td {
                    background-color: $grey-light;
                }   
            }
            .sum-price {
                line-height: 1.85em;

                td {
                    border-top: solid 2px $grey-dark;
                    /*background-color: $grey-dark;*/
                }
                .pay-detail {
                    p {
                        font-size: 30px;
                        font-weight: $font-medium;
                    }
                }
            }
        }
    }
    .SummaryAddress {
        margin-bottom: 20px;
        
        ul {
            padding-bottom: 10px;
            li {
                padding-top: 1px;
                padding-bottom: 1px;
            }
        }
    }
    .checkout-option {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: solid 1px #efefef;
    }
    .SummaryInfo {
        h3 {
            font-size: 23px;
            line-height: 30px;
        }
        textarea {
            height: 130px;
        }
        .btn-primary {
            width: 100%;
        }
    }
    .checkout-box {
        background-color: #FFF;

        .delivery-list {
            margin-bottom: 15px;
            border: solid 1px $grey-dark;

            h3 {
                background-color: $grey-dark;
                /*background-color: #dfe2e3;*/
                padding: 7px 10px;
            }
        }
        
        + h2 { margin-top: 20px; }

        .methods {
            li {
                label {
                    display: block;
                    line-height: 25px;
                }
            }
        }
    }
    .DeliverySelect {
        width: 100%;
        @media(width > $monitor) {
            width: 50%;
        }
    }
    .DeliveryOptions {
        margin-top: 10px;
    }
    .payment-select {
        margin-top: 10px;
    }
    .check-box {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.error.errorMessage {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: red;
    font-size: 23px;
}
.select2-results {
    /*font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;*/
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #dfe2e3;
    color: black;
}
